.user-list-item-container {
  display: flex;
  flex-flow: row wrap;
}

.user-list-item-details {
  order: 1;
  flex: 2;
}

.user-list-item-process {
  order: 2;
}

.list-button {
  margin: 0px 10px 0px 0px;
}
