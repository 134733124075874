body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #192a56;
  color: white;
}

.navbar {
  background-color: #273c75;
}

.pagination {
  margin-top: 10px;
}

.page-item.disabled .page-link {
  background-color: #2f3f6f;
  border-color: #374c85;
  color: #6f7faf;
}

.page-item .page-link {
  background-color: #1f2f5f;
  border-color: #374c85;
  color: white;
}

.list-group-item {
  background-color: #1f2f5f;
  border-color: #374c85;
}

.list-group-item.active {
  background-color: #273c75;
  border-color: #576ca5;
}

.form-control:disabled {
  background-color: #2f3f6f;
  border-color: #374c85;
  color: #6f7faf;
}

.form-control {
  background-color: #1f2f5f;
  border-color: #374c85;
  color: white;
}

.table {
  background-color: #1f2f5f;
  border-color: #374c85;
  color: white;
}

.table tbody tr:hover {
  background-color: #2f3f6f;
  border-color: #374c85;
  color: white;
}

.card-header {
  background-color: #2f3f6f;
}

.card-footer {
  background-color: #1a2a5a;
  border-color: #374c85;
}

.card-body {
  background-color: #1f2f5f;
  border-color: #374c85;
}

.card {
  margin: 10px auto;
  border-color: #374c85;
}
