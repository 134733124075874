.no-purchase {
  color: #ddd;
}

.title {
  font-size: 1rem;
  color: #efefef;
  display: block;
}

.sub-title {
  font-size: .9rem;
  color: #bcbcbc;
  display: block;
}
