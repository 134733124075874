.users-list-container {
  width: 100%;
  order: 1;
  flex: 1;
  /* position: relative;
  height: calc(100vh - 100px);
  overflow: hidden;
  overflow-y: scroll */
}

.users-list {
  padding: 10px
}

.users-list .badge-danger {
  background-color: #374c85;
  color: #778cc5
}

.users-list .badge-success {
  background-color: #576ca5;
}

.go-button {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px
}

.search-form {
  padding: 10px 0px
}

@media (min-width: 769px) {
  .users-list-container {
    max-width: 20em;
    min-width: 17em;
  }
}
