.details-container {
  order: 2;
  flex: 2;
  position: relative;
  height: calc(100vh - 60px);
  overflow: hidden;
  overflow-y: scroll
}

.user-details {
  padding: 1em
}

#user-detail-form .container {
  margin: 0px;
  padding: 0px;
}

@media (min-width: 1200px) {
  #user-detail-form .container {
    max-width: 100%;
  }
}
