body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #192a56;
  color: white;
}

.navbar {
  background-color: #273c75;
}

.pagination {
  margin-top: 10px;
}

.page-item.disabled .page-link {
  background-color: #2f3f6f;
  border-color: #374c85;
  color: #6f7faf;
}

.page-item .page-link {
  background-color: #1f2f5f;
  border-color: #374c85;
  color: white;
}

.list-group-item {
  background-color: #1f2f5f;
  border-color: #374c85;
}

.list-group-item.active {
  background-color: #273c75;
  border-color: #576ca5;
}

.form-control:disabled {
  background-color: #2f3f6f;
  border-color: #374c85;
  color: #6f7faf;
}

.form-control {
  background-color: #1f2f5f;
  border-color: #374c85;
  color: white;
}

.table {
  background-color: #1f2f5f;
  border-color: #374c85;
  color: white;
}

.table tbody tr:hover {
  background-color: #2f3f6f;
  border-color: #374c85;
  color: white;
}

.card-header {
  background-color: #2f3f6f;
}

.card-footer {
  background-color: #1a2a5a;
  border-color: #374c85;
}

.card-body {
  background-color: #1f2f5f;
  border-color: #374c85;
}

.card {
  margin: 10px auto;
  border-color: #374c85;
}

/* .app-container {
  
} */

.app {
  display: flex;
  flex-flow: column wrap;
}

.root {
  height: 100%;
}

@media (min-width: 769px) {
  .app {
    flex-flow: row wrap;
  }
}

.page-title {
  flex: 10 1;
}

.login-button-container {
  margin-top: auto;
  margin-bottom: auto;
}

.login-link {
  padding: 10px;
}

.stats-link {
  padding: 10px;
}

.users-link {
  padding: 10px;
}

.details-container {
  order: 2;
  flex: 2 1;
  position: relative;
  height: calc(100vh - 60px);
  overflow: hidden;
  overflow-y: scroll
}

.user-details {
  padding: 1em
}

#user-detail-form .container {
  margin: 0px;
  padding: 0px;
}

@media (min-width: 1200px) {
  #user-detail-form .container {
    max-width: 100%;
  }
}


.lists-container {
  padding: 10px 10px 10px 0px;
  order: 1;
  flex: 1 1;
    /* position: relative; */
    /* height: calc(100vh - 100px); */
    /* overflow: hidden; */
    /* overflow-y: scroll */
}

.user-list-item-container {
  display: flex;
  flex-flow: row wrap;
}

.user-list-item-details {
  order: 1;
  flex: 2 1;
}

.user-list-item-process {
  order: 2;
}

.list-button {
  margin: 0px 10px 0px 0px;
}

#container {
  padding: 1rem;
}

.stat-large {
  font-size: 3em;
}

.login-container {
  padding: 10px;
  width: 80%;
  margin: auto;
}

.users-list-container {
  width: 100%;
  order: 1;
  flex: 1 1;
  /* position: relative;
  height: calc(100vh - 100px);
  overflow: hidden;
  overflow-y: scroll */
}

.users-list {
  padding: 10px
}

.users-list .badge-danger {
  background-color: #374c85;
  color: #778cc5
}

.users-list .badge-success {
  background-color: #576ca5;
}

.go-button {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px
}

.search-form {
  padding: 10px 0px
}

@media (min-width: 769px) {
  .users-list-container {
    max-width: 20em;
    min-width: 17em;
  }
}

.no-purchase {
  color: #ddd;
}

.title {
  font-size: 1rem;
  color: #efefef;
  display: block;
}

.sub-title {
  font-size: .9rem;
  color: #bcbcbc;
  display: block;
}

