/* .app-container {
  
} */

.app {
  display: flex;
  flex-flow: column wrap;
}

.root {
  height: 100%;
}

@media (min-width: 769px) {
  .app {
    flex-flow: row wrap;
  }
}
